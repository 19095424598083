import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getToken } from "@/utils/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    redirect: "/home",
    component: () => import("../components/layout/index.vue"),
    children: [
    ],
  },
  {
    path: "/",
    name: "index",
    redirect: "/home",
    component: () => import("../components/layout/index.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home/home.vue")
      },

      {
        path: "/3d/:id",
        name: "3D",
        component: () => import("../components/iframe/IFrame.vue")
      },
    ],
  },

];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeEach(async (to, from, next) => {
  let token = getToken("station_token");
  if (!token) {
    const url = window.location.href
    window.location.href = process.env.VUE_APP_LOGIN_URL + "?back=" + url;
    next();
  }
  else {
    next();
  }
});

export default router;
