import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import "@/assets/css/reset.css";


const app = createApp(App)
app.use(ElementPlus, { locale })
app.use(router)
app.mount('#app')

