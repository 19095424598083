import Cookies from 'js-cookie'

// const TokenKey = 'Admin-Token'

export function getToken(Token:any) {
  return Cookies.get(Token)
}

export function setToken(Token:any, token:any) {
  return Cookies.set(Token, token)
}

export function removeToken(Token:any) {
  return Cookies.remove(Token, { domain: process.env.VUE_APP_COOKIES_DOMAIN })
}
